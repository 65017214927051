@use 'styles/scss/variables.scss' as vars;

.custom-card-container {
  max-width: 270px;
  max-height: 365px;
  min-height: 365px;
  width: 100%;
  height: 100%;
  padding-bottom: 30px;

  // min-width: 250px;
  // min-height: 355px;
  border-radius: 20px;
  background-color: vars.$black;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s all;

  .custom-card-band {
    position: absolute;
    top: 10%;
    right: 0;
    padding: 0 18px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 0 0 10px;
    background-color: vars.$black;

    .band-text {
      font-family: vars.$gotham-bold;
      font-weight: bolder;
      margin-bottom: -8px;
    }
  }

  .image-container {
    width: calc(100% - 14px);
    height: 70%;
    border-radius: 20px;
    margin-top: 7px;
    overflow: hidden;
    background-color: vars.$dark-grey;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  .title-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0 10%;
    margin-top: 8%;
    gap: 4px;

    .title-text {
      font-size: 14px;
      font-weight: bolder;
      font-family: vars.$gotham-bold;
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0;
    }

    span {
      margin-top: -3px;
    }



  }

  .desc-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0 10%;
    margin-top: 2%;
    gap: 4px;

    .desc-text {
      font-size: 11px;
      font-weight: bold;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    span.by-text {
      font-size: 11px;
      font-family: 'Gotham Light';
      font-weight: 100;
      margin-top: -10px;
    }

    span.desc-icon {
      font-size: 10px;
      margin-top: -3px;

      &.gold {
        color: #FFD700;

      }

      &.green {

        color: vars.$success-color ;
      }

      &.platinium {

        color: #E5E4E2;

      }
    }


  }

  .learn-more-container {
    position: absolute;
    bottom: 4px;
    right: 10px;
    transition: 0.3s bottom;

    .lm-text {
      cursor: pointer;
      font-size: 11px;
      margin: 0;
      transition: 0.3s color;
      // line-height: 0;
    }
  }

  .delete-btn {
    position: absolute;
    bottom: 5px;
    left: 15px;

    span {
      font-size: 12px;
    }
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      transform: scale(1.02);
      box-shadow: vars.$box-shadow-invert;

      .learn-more-container {
        bottom: 5px;

        .lm-text {
          color: vars.$primary-color;
        }
      }
    }

    &:active {
      transform: scale(0.96);
      background-color: vars.$primary-color;
      box-shadow: vars.$box-shadow-invert;

      .learn-more-container {
        bottom: 5px;

        .lm-text {
          color: vars.$black;
        }
      }

      .title-container {
        .title-text {
          color: vars.$black;
        }
      }

      .desc-container {
        .desc-text {
          color: vars.$black;
        }

        span.by-text {
          color: vars.$black;
        }
      }
    }
  }

  @media (max-width: vars.$breakpoint-md) {
    max-height: 265px;
    min-height: 265px;

    .image-container {
      height: 65%;
    }

    .custom-card-band {
      padding: 4px 6px;

      .band-text {
        font-size: 11px;
      }
    }

    .title-container {
      padding: 0 7%;
      margin-top: 6%;
      gap: 3px;

      .title-text {
        font-size: 11px;
        font-weight: bolder;
        max-width: 80%;
      }

      span {
        font-size: 12px;
        margin-top: -3px;
      }
    }

    .desc-container {
      padding: 0 8%;
      margin-top: 1%;
      gap: 4px;

      .desc-text {
        font-size: 9px;
      }

      span.by-text {
        font-size: 9px;
      }

      span.desc-icon {
        font-size: 9px;
      }
    }

    .learn-more-container {
      .lm-text {
        font-size: 10px;
      }
    }
  }

  @media screen and (min-width: vars.$breakpoint-md) and (max-width: vars.$breakpoint-xl) {
    max-height: 265px;
    min-height: 265px;

    .image-container {
      height: 65%;
    }

    .custom-card-band {
      padding: 5px 8px;

      .band-text {
        font-size: 12px;
      }
    }

    .title-container {
      padding: 0 8%;
      margin-top: 5%;
      gap: 4px;

      .title-text {
        font-size: 11px;
        font-weight: bolder;
        max-width: 80%;
      }

      span {
        font-size: 12px;

      }
    }

    .desc-container {
      padding: 0 8%;
      margin-top: 1%;
      gap: 4px;

      .desc-text {
        font-size: 9px;
      }

      span.by-text {
        font-size: 9px;
      }

      span.desc-icon {
        font-size: 9px;
      }
    }

    .learn-more-container {
      .lm-text {
        font-size: 9px;
      }
    }
  }

  @media screen and (min-width: vars.$breakpoint-xl) and (max-width: vars.$breakpoint-xxl) {
    max-width: 200px;
    max-height: 285px;
    min-height: 285px;

    .image-container {
      width: calc(100% - 14px);
      height: 60%;
    }

    .title-container {
      .title-text {
        font-size: 12px;
      }
    }

    .desc-container {
      .desc-text {
        font-size: 9px;
      }

      span.by-text {
        font-size: 9px;
      }

      span.desc-icon {
        font-size: 9px;
      }
    }

    .learn-more-container {
      .lm-text {
        font-size: 9px;
      }
    }
  }

  @media (min-width: vars.$breakpoint-xxl) {
    max-width: 280px;
    max-height: 365px;
    min-height: 365px;
  }
}

.selected-custom-card-container {
  transform: scale(0.96);
  background-color: vars.$primary-color;
  box-shadow: vars.$box-shadow-invert;

  .learn-more-container {
    bottom: 5px;

    .lm-text {
      color: vars.$black;
    }
  }

  .title-container {
    .title-text {
      color: vars.$black;
    }
  }

  .desc-container {
    .desc-text {
      color: vars.$black;
    }

    span.by-text {
      color: vars.$black;
    }
  }

  .learn-more-container {
    .lm-text {
      color: vars.$white
    }
  }

  &:hover {
    .learn-more-container {
      .lm-text {
        color: vars.$black;
      }
    }
  }
}