// dashboard layout

.default-layout-wrapper {
  min-height: 100vh;

  .dashboard-header {
    height: 66px;
    background-color: $header-bg;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;

    .header-left {
      height: 100%;
      display: flex;

      img {
        height: 100%;
        margin-right: 5px;
      }

      .header-hamburger {
        height: 100%;
        width: 0px;
        overflow: hidden;
        display: flex;
        align-items: center;
        transition: 0.3s width;

        .hamburger-container {
          height: 34px;
          width: 34px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          transition: 0.3s all;
          cursor: pointer;
          border-radius: 18px;

          span {
            transition: 0.3s all;
            font-size: 0;
          }

          &:hover {
            background-color: $black-20;

            span {
              transform: scale(0.9);
            }
          }
        }
      }

      .show-header-hamburger {
        width: 34px;

        .hamburger-container {
          span {
            font-size: 24px;
          }
        }
      }

      .header-title-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .header-title {
          color: $font-invert-color;
          margin: 0;
        }
      }
    }

    .header-right {
      padding: 0 10px;
      height: 100%;
      display: flex;
      align-items: flex-end;
      transition: 0.3s all;

      .header-right-container {
        display: flex;
        align-items: center;
        gap: 15px;

        .header-right-avatar {
          height: 54px;
          width: 54px;
          flex-shrink: 0;
          border-radius: 27px;
          border: 1px solid #000;
          transition: 0.3s all;
        }

        .header-right-text {
          margin-bottom: -4px;
          color: $font-invert-color;
        }
      }
    }

    .header-right-md-down {
      align-items: center;
      min-width: 65px;
      max-width: 65px;

      .header-right-container {
        .header-right-avatar {
          height: 44px;
          width: 44px;
        }
      }
    }
  }

  .dashboard-sidebar {
    background-color: $sidebar-bg;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 66px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .body-container {
    width: 100%;
  }

  .content-layout {
    border-radius: 0 20px 20px 0;
    background-color: $header-bg;

    .content-wrapper {
      position: relative;
      background-color: #EBEBEB;
      max-height: calc(100vh - 75px);
      border-radius: 0 20px 20px 0;
      transition: 0.3s border-radius;
      padding: 0 20px;

      .content-auto {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
      }

      // overflow: auto;
      @media (max-width: $breakpoint-md) {
        border-radius: 0;
        max-height: calc(100vh - 66px);
      }
    }
  }
}

.auth-layout-wrapper {
  background-color: #F4F4F4;
  position: relative;
  height: 100vh;



  .auth-header {
    height: 66px;
    padding: 0 30px;
    display: flex;
    gap: 5px;
    align-items: center;

    img {
      width: 66px;
      height: 66px;
    }
  }

  .auth-layout-inner {
    position: absolute;
    padding: 44px 70px;
    top: 66px;
    right: 13px;
    bottom: 12px;
    border-radius: 0px 20px 20px 0px;
    background-color: #1C1C1C;
    width: 100%;
    overflow-y: auto;

    .auth-heading {
      color: #D8AF56;
      font-family: $gotham-bold;
    }

    .app-logo-huge-wrapper {
      justify-content: center;
      display: flex;
      align-items: center;

      .app-logo-huge {
        max-width: 500px;
        width: 100%;
        height: auto;
      }
    }

    .auth-form-wrapper {
      max-width: 360px;

      .auth-form {
        margin-top: 70px;

        .auth-form-heading {
          margin-bottom: 25px;
        }

        .auth-btn {
          background-color: $primary-color;
          border-radius: 5px;
          height: 42px;
        }
      }
    }
  }

}