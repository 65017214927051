@use 'styles/scss/variables.scss' as vars;

.chat-sidebar-container {
  height: 100%;
  background: vars.$header-bg !important;
}

.chat-sidebar-drawer {
  .ant-drawer-content-wrapper {
    max-width: 320px;
    .ant-drawer-wrapper-body {
      width: 100%;
      .ant-drawer-body {
        padding: 20px 0;
        background: vars.$header-bg !important;
      }
    }
  }
}
.cl-open-chat {
  position: fixed;
  top: 20%;
  right: 5px;
  cursor: pointer;
  height: 44px;
  width: 44px;
  font-size: 24px;
  border-radius: 18px;
  background-color: vars.$white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
  z-index: 50;
  box-shadow: vars.$box-shadow;
  &:hover {
    border-radius: 22px;
    background-color: vars.$primary-color;
    color: vars.$white;
  }
}
