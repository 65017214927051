@use 'styles/scss/variables.scss' as vars;

.mentorship-details-page-container {
  padding: 40px 50px 0 50px;
  height: 100%;

  .mentorship-details-container {
    max-height: 500px;
  }

  .mdp-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .mdp-image {
      width: 100%;
      height: 495px;
      object-fit: cover;
      border-radius: 20px;
      max-width: 420px;
      min-height: 340px;
    }
  }

  .mdp-details {
    display: flex;
    flex-direction: column;
    padding: 30px 0 0 40px;
    height: 100%;
    justify-content: space-between;

    .mdp-title-container {
      display: flex;
      align-items: center;
      gap: 10px;

      .mdp-title {
        font-family: vars.$gotham-bold;
        font-weight: bolder;
        color: vars.$primary-color;
      }

      .mdp-icon {
        margin-top: -25px;
        font-family: vars.$gotham-bold;
        font-size: 26px;
        font-weight: bolder;

      }
    }

    .mdp-about-title {
      font-family: vars.$gotham-bold;
      font-weight: bolder;
      color: vars.$primary-color;
      margin-bottom: -10px;
    }

    .mdp-about-description {
      color: black;
      font-weight: lighter;
    }

    .mdp-button-container {
      margin-top: 10px;
      gap: 15px;

      button {
        width: 190px;
      }
    }

    .mdp-social-container {
      margin-top: 20px;
      gap: 20px;

      .mdp-social-text {
        font-size: 16px;
        margin-bottom: -2px;
      }
      .svg-wrapper {
        cursor: pointer;
      }
    }
  }

  .mdp-latest-title {
    font-family: vars.$gotham-bold;
    font-weight: bolder;
    color: vars.$primary-color;
    margin-top: 30px;
  }

  .mdp-post-list-container {
    max-height: calc(100% - 575px);
    padding: 0 10px;
    overflow-y: auto;
    overflow-x: hidden;

    .mdp-post-container {
      margin-bottom: 40px;

      img {
        height: 400px;
        width: calc(100% - 20px);
        object-fit: cover;
      }
    }
  }
}