.un-draggable {
  user-zoom: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

// Cards container styling

.cards-main-container {
  height: 100%;

  .cards-title {
    position: absolute;
    padding: 40px 20px;
    height: 200px;
    width: calc(100% - 40px);
    pointer-events: none;
    display: flex;
    justify-content: space-between;
    background: $tb-gradient;
    z-index: 10;

    .title {
      color: $primary-color;
      font-weight: bolder;
      font-family: $gotham-bold;
      text-transform: capitalize;
    }

    button {
      pointer-events: all;
      z-index: 99999;

      span {
        font-family: $gotham-bold;
      }
    }
  }

  .cards-list-container {
    display: flex;
    width: 100%;
    height: calc(100%);
    padding: 100px 10px 10px 10px;
    flex-wrap: wrap;
    gap: 20px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.page-title {
  color: $primary-color !important;
  font-weight: bolder !important;
  font-family: $gotham-bold !important;
}

.page-container {
  padding-top: 40px;
  padding-right: 10px;
  padding-left: 10px;

}


.form-style-1 {
  label {
    color: $primary-color !important;
    font-family: $gotham-medium !important;

  }

  textarea.input-style-1 {
    padding-top: 10px;
    padding-left: 10px;
    border-radius: 20px;
  }


  .input-style-1 {
    color: $black;
    border-radius: 30px;
    background-color: $white;
    caret-color: $primary-color;
    height: 38px;




    .ant-input {
      background-color: transparent !important;
      color: $black;
    }

    .ant-select-selector {
      height: 100%;
      border-radius: 30px;
      background-color: $white;
      color: $black;

      .ant-select-selection-search {
        .ant-select-selection-search-input {
          height: 100% !important;
        }
      }

      .ant-select-selection-item {
        line-height: 36px;

      }

      .ant-select-selection-placeholder {
        line-height: 35px !important;
        color: $black;
      }
    }

    .ant-input-number-input-wrap {
      height: 100%;

      input {
        height: 100%;
        color: $black;
      }


    }

    .ant-select-arrow {
      color: $black;
    }

    &::placeholder {
      color: $black;
      font-size: 12px;
      font-family: $gotham-medium;
    }

  }
}

.btn-modal-variant {
  border-radius: 20px;
  height: 41px;

  &.with-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  span {
    font-family: $gotham-bold;
    font-size: 1.2em;
  }
}

.radio-text-white {
  .ant-radio+span {
    color: $white;
    font-family: $gotham-medium;
  }
}

.steps-progress-wrapper {
  width: 264px;
  height: 41px;
  border-radius: 20px;
  background-color: #222222;
  position: relative;

  .progress-spinner {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


  .active-step {
    display: inline-flex;
    align-items: center;
    z-index: 1;
    padding-left: 10px;
    height: 100%;
    background-color: $primary-color;
    border-radius: 20px;
    transition: 0.3s width ease-in-out;
    position: absolute;
    cursor: pointer;

  }

  .arrow-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 1;
  }

  .steps-text {
    position: absolute;
    z-index: 0;
    right: 55px;
    top: 50%;
    transform: translateY(-50%);

  }

}