@use 'styles/scss/variables.scss' as vars;

// Primary Button
.gc-primary-btn {
  height: 50px;
  border-radius: 20px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: vars.$primary-color;
  .gc-primary-btn-text {
    color: vars.$font-invert-color;
    font-weight: bold;
  }
}
