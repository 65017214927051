@use 'styles/scss/variables.scss' as vars;

.affiliate-page-container {
  padding: 60px 100px 0px 200px;
  max-width: 1920px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ah-header-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    .ah-avatar-text {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 20px;
      .ah-avatar {
        height: 112px;
        width: 112px;
        border-radius: 50%;
        background-color: white;
      }
      .ah-user-details {
        display: flex;
        flex-direction: column;
        .ah-username {
          display: flex;
          align-items: center;
          gap: 15px;
          font-size: 50px;
          .username {
            font-weight: bolder;
            margin-bottom: -18px;
            white-space: nowrap;
            max-width: 300px;
            overflow: hidden;
            line-height: 40px;
            text-overflow: ellipsis;
          }
          .ah-image {
            height: 28px;
          }
        }
        .ah-package-text {
          margin-top: 15px;
          font-weight: bolder;
          margin-bottom: -8px;
          color: vars.$primary-color;
        }
      }
    }
  }
  .ac-copy-container {
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
    .ac-copy-img {
      width: 45px;
      cursor: pointer;
      transition: 0.3s all;
      &:hover {
        filter: brightness(0.8);
      }
    }
    .ac-link {
      max-width: 95%;
      font-weight: bold;
      font-size: 21px;
      margin-bottom: -3px;
      border-radius: 8px;
      border: 2px dashed vars.$white;
      line-height: 0;
      height: 45px;
      padding: 0 20px;
      padding-top: 12px;
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-family: vars.$gotham-bold;
      span.highlighted {
        color: vars.$primary-color;
        margin-right: 10px;
        font-family: vars.$gotham-bold;
        font-weight: bolder;
      }
      span.link {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 32px;
        transition: 0.3s all;
        &:hover {
          cursor: pointer;
          transform: scale(0.99);
          color: vars.$primary-color;
        }
        &:active {
          color: vars.$primary-color-dark;
          transform: scale(0.95);
        }
        // width: 50%;
      }
    }
  }

  .adc-card-container {
    .adc-card-title {
      margin-top: 0px;
      margin-bottom: 20px;
      color: vars.$primary-color;
      font-weight: bolder;
      font-family: vars.$gotham-bold;
    }
    .card-container {
      height: 204px;
      border-radius: 20px;
      display: flex;
      width: calc(100% - 30px);
      margin-left: 15px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      background-color: vars.$white;
      h1,
      div,
      p,
      span {
        color: vars.$black;
      }
      .number {
        font-size: 121px;
        margin-bottom: -20px;
        margin-top: 0px;
        margin-bottom: 0px;
        font-family: vars.$gotham-bold;
      }
    }
    .invited-card {
      background-color: vars.$primary-color;
    }
    .withdraw-card {
      background-color: white;
      .number {
        font-size: 50px;
        margin-bottom: -10px;
        margin-top: 20px;
      }
      button {
        height: 40px;
        border-radius: 15px;
        background-color: vars.$white;
        box-shadow: vars.$box-shadow;
      }
    }
  }

  .ag-goal-container {
    margin-top: 20px;
    .info-container {
      display: flex;
      align-items: center;
      margin-top: 10px;
      gap: 10px;
      justify-content: space-between;
      h3 {
        font-family: vars.$gotham-light;
        color: vars.$primary-color;
        margin: 0;
        margin-bottom: -18px;
        line-height: 35px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .highlighted {
        font-family: vars.$gotham-bold;
        margin-bottom: -16px;
      }
      .info-right {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .level-container {
        display: flex;
        align-items: center;
        margin-left: 10px;
        gap: 10px;
        img {
          width: 30px;
        }
        .platinum {
          color: vars.$platinum-color;
          margin-bottom: -15px;
        }
      }
    }
    .goal-progress-track {
      margin: 20px 0;
      width: 100%;
      height: 34px;
      border-radius: 20px;
      background-color: vars.$white;
      padding: 3px;
      .goal-progress-bar {
        background: vars.$primary-gradient;
        height: 100%;
        border-radius: 20px;
        transition: 0.3s all;
      }
    }
  }

  .ags-start-container {
    padding: 30px 20px 0 0px;
    .ags-title {
      .title {
        font-family: vars.$gotham-bold;
        color: vars.$primary-color;
      }
      .small {
        font-size: 26px;
      }
      .paragraph {
        font-size: 16px;
      }
      .smaller {
        font-size: 12px;
      }
    }
    .ags-thumbnail {
      display: flex;
      align-items: flex-end;
      padding-left: 20px;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
  .as-subscription-container {
    position: relative;
    margin-top: -60px;
    width:100%;
    // padding-right: 50px;
    display: flex;
    // margin-bottom: -35px;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 20px;
    overflow: hidden;
    .as-sub-card-container {
      width: 250px;
      border-radius: 20px 20px 0 0;
      background-color: vars.$dark-grey;
      overflow: hidden;
      height: 310px;
      .top-title-container {
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        .title {
          font-size: 21px;
          color: vars.$black;
          margin-bottom: -8px;
          text-transform: capitalize;
        }
      }

      .silver {
        background-color: vars.$grey;
      }
      .gold {
        background-color: vars.$primary-color;
      }
      .platinum {
        background-color: vars.$platinum-color;
      }

      .bottom-desc-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px 5px;
        .highlighted {
          font-family: vars.$gotham-bold;
          margin-bottom: -10px;
          width: fit-content;
          text-align: center;
          color: vars.$primary-color;

        }
      }
    }
  }
}
.affiliate-page-container-2 {
  padding: 60px 100px 0px 200px;
  max-width: 1920px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  //justify-content: space-between;

  .ah-header-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    .ah-avatar-text {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 20px;
      .ah-avatar {
        height: 112px;
        width: 112px;
        border-radius: 50%;
        background-color: white;
      }
      .ah-user-details {
        display: flex;
        flex-direction: column;
        .ah-username {
          display: flex;
          align-items: center;
          gap: 15px;
          font-size: 45px;
          .username {

            font-weight: bolder;
            margin-bottom: -18px;
            white-space: nowrap;
            max-width: 300px;
            overflow: hidden;
            line-height: 40px;
            text-overflow: ellipsis;
          }
          .ah-image {
            height: 28px;
          }
        }
        .ah-package-text {
          margin-top: 15px;
          font-weight: bolder;
          margin-bottom: -8px;
          color: vars.$primary-color;
        }
      }
    }
  }
  .ac-copy-container {
    margin-top: 0px;
    display: flex;
    align-items: center;
    gap: 20px;
    .ac-copy-img {
      width: 51px;
      cursor: pointer;
      transition: 0.3s all;
      &:hover {
        filter: brightness(0.8);
      }
    }
    .ac-link {
      max-width: 95%;
      width: 100%;
      font-weight: bold;
      font-size: 31px;
      margin-bottom: -3px;
      border-radius: 8px;
      border: 2px dashed vars.$white;
      line-height: 0;
      height: 51px;
      padding: 0 20px;
      padding-top: 0px;
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-family: vars.$gotham-bold;
      span.highlighted {
        color: vars.$primary-color;
        margin-right: 10px;
        font-family: vars.$gotham-bold;
        font-weight: bolder;
      }
      span.link {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 32px;
        transition: 0.3s all;
        color: black;
        &:hover {
          cursor: pointer;
          transform: scale(0.99);
          color: vars.$primary-color;
        }
        &:active {
          color: vars.$primary-color-dark;
          transform: scale(0.95);
        }
        // width: 50%;
      }
    }
  }

  .adc-card-container {
    .adc-card-title {
      margin-top: 0px;
      margin-bottom: 30px;
      color: vars.$primary-color;
      font-weight: bolder;
      font-family: vars.$gotham-bold;
    }
    .card-container {
      height: 204px;
      border-radius: 20px;
      display: flex;
      width: calc(100% - 30px);
      margin-left: 15px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      background-color: vars.$white;
      h1,
      div,
      p,
      span {
        color: vars.$black;
      }
      .number {
        font-size: 121px;
        margin-bottom: -20px;
        margin-top: 0px;
        margin-bottom: 0px;
        font-family: vars.$gotham-bold;
      }
    }
    .invited-card {
      background-color: vars.$primary-color;
    }
    .withdraw-card {
      background-color: white;
      .number {
        font-size: 50px;
        margin-bottom: -10px;
        margin-top: 20px;
      }
      button {
        height: 40px;
        border-radius: 15px;
        background-color: vars.$white;
        box-shadow: vars.$box-shadow;
      }
    }
  }

  .ag-goal-container {
    margin-top: 0px;
    .info-container {
      display: flex;
      align-items: center;
      margin-top: 10px;
      gap: 10px;
      justify-content: space-between;
      h3 {
        font-family: vars.$gotham-light;
        color: vars.$primary-color;
        margin: 0;
        margin-bottom: 20px;
        line-height: 35px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .highlighted {
        font-family: vars.$gotham-bold;
        margin-bottom: 20px;
      }
      .info-right {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .level-container {
        display: flex;
        align-items: center;
        margin-left: 10px;
        gap: 10px;
        img {
          width: 30px;
          margin-bottom: 20px;
        }
        .platinum {
          color: vars.$platinum-color;
          margin-bottom: 25px;
        }
      }
    }
    .goal-progress-track {
      margin: 20px 0;
      width: 100%;
      height: 34px;
      border-radius: 20px;
      background-color: vars.$white;
      padding: 3px;
      .goal-progress-bar {
        background: #57C1EB;
        height: 100%;
        border-radius: 20px;
        transition: 0.3s all;
      }
    }
  }

  .ags-start-container {
    padding: 30px 20px 0 0px;
    .ags-title {
      .title {
        font-family: vars.$gotham-bold;
        color: vars.$primary-color;
      }
      .small {
        font-size: 26px;
      }
      .paragraph {
        font-size: 16px;
      }
      .smaller {
        font-size: 12px;
      }
    }
    .ags-thumbnail {
      display: flex;
      align-items: flex-end;
      padding-left: 20px;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
  .as-subscription-container {
    position: relative;
    margin-top: -60px;
    width:100%;
    // padding-right: 50px;
    display: flex;
    // margin-bottom: -35px;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 20px;
    overflow: hidden;
    .as-sub-card-container {
      width: 250px;
      border-radius: 20px 20px 0 0;
      background-color: vars.$dark-grey;
      overflow: hidden;
      height: 310px;
      .top-title-container {
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        .title {
          font-size: 21px;
          color: vars.$black;
          margin-bottom: -8px;
          text-transform: capitalize;
        }
      }

      .silver {
        background-color: vars.$grey;
      }
      .gold {
        background-color: vars.$primary-color;
      }
      .platinum {
        background-color: vars.$platinum-color;
      }

      .bottom-desc-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px 5px;
        .highlighted {
          font-family: vars.$gotham-bold;
          margin-bottom: -10px;
          width: fit-content;
          text-align: center;
          color: vars.$primary-color;

        }
      }
    }
  }
}
