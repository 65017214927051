@use 'styles/scss/variables.scss' as vars;

.sidebar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .ds-setting-container {
    padding: 20px 40px;
  }
}
.dashboard-drawer-sidebar {
  .ant-drawer-content-wrapper {
    max-width: 270px !important;
    width: 90% !important;
  }
}
.go-live-wrapper {
  height: 50px;
  padding: 0 12px;
  margin: 0 28px;
  margin-top: 40px;
  border-radius: 20px;
  background-color: vars.$white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > .left {
    display: inline-flex;
    gap: 10px;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .go-live-text {
    display: flex;
    align-items: center;
    color: vars.$black;
    font-size: 14px;
    font-weight: bold;
  }
  .right {
    display: inline-flex;
    gap: 5px;
    font-weight: bold;
  }
}
.d-sidebar-list-items {
  margin-top: calc(5vh + 50px);

  .sli-container {
    height: 50px;
    width: 250px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    background-color: #E0E0E0;
    padding-left: 7px;
    margin-bottom: 14px;
    margin-left: auto;
    border-radius: 25px 0 0 25px;
    transition: 0.3s background-color;
    .sli-img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      background-color: rgb(87, 193, 235, 0.15);
      border-radius: 20px;
      transition: 0.3s background-color;
      .sli-img {
        height: 50%;
        width: 50%;
      }
    }
    .sli-text-container {
      height: 40px;
      width: 100%;
      background-color: vars.$transparent;
      display: flex;
      align-items: center;
      padding: 0 5px 0 15px;
      border-radius: 0px;
      transition: 0.3s all;
      .sli-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: vars.$dark-grey;
      }
    }
  }
  .sli-container-selected {
    background-color: vars.$white;

    .sli-img-container {
      background-color: rgb(87, 193, 235, 0.1);
    }
    .sli-text-container {
      height: 50px;
      background-color: vars.$white;
      border-radius: 20px 0 0 20px;
      .sli-text {
        font-weight: bold;
        color: vars.$primary-color;
      }
    }
  }
}
