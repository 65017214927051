@use 'styles/scss/variables.scss' as vars;

.custom-input-wrapper {
    display: flex;
    flex-direction: column;

    label {
        color: black;
        margin-bottom: 8px;
        font-family: vars.$gotham-bold;
    }

    input {
        outline: none;
        border: 0;
        padding: 0 10px;
        color: vars.$black;
        width: 100%;
        height: 34px;
        background: lighten($color: #1A1A1A, $amount: 4);
        border-bottom: 2px solid vars.$primary-color;

        &::placeholder {
            color: grey;
        }
    }

    &.error {

        label,
        input {
            color: vars.$error-color;
        }
    }

    .form-error-message {
        color: vars.$error-color;
        margin-top: 7px;
        font-size: 12px;
    }
}