@use 'styles/scss/variables.scss' as vars;

.educator-page-container {
  height: 100%;
  padding: 40px 20px 0;
  display: flex;
  gap: 20px;

  .educators-list-container {
    padding-bottom: 20px;
    width: 300px;
    min-width: 310px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
    overflow: auto;
    padding-right: 10px;
    direction: rtl;

    .educator-list-item-selected {
      background-color: vars.$black !important;
    }

    .educator-list-item {
      cursor: pointer;
      width: calc(100% - 30px);
      height: 110px;
      min-height: 110px;
      border-radius: 18px;
      background-color: vars.$sidebar-bg;
      display: flex;
      direction: ltr;
      align-items: center;
      gap: 10px;
      padding: 8px;
      transition: 0.3s all;

      &:hover {
        transform: scale(1.02);
        box-shadow: vars.$box-shadow-invert;
        background-color: vars.$black;
      }

      &:active {
        transform: scale(0.98);
      }

      .edl-image {
        //width: 35%;
        aspect-ratio: 1 / 1;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
      }

      .educator-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;
        padding: 0 0 8px 0;
        direction: ltr;
        width: 100%;

        .educator-detail-info-container {
          margin-top: 10px;
          width: 100%;

          .educator-title {
            gap: 10px;
            margin-bottom: 10px;
            width: 100%;

            .educator-title-text {
              font-family: vars.$gotham-bold;
              font-weight: bolder;
              font-size: 17px;
            }

            .educator-title-icon {
              color: vars.$success-color;
            }

            .educator-title-icon.platinum {
              color: vars.$primary-color;
            }
          }

          .educator-desc-text {
            font-size: 11px;
            margin-bottom: 0;
            overflow: hidden;
            white-space: nowrap;
            max-width: 150px;
            text-overflow: ellipsis;
          }
        }

        .educator-call-text {
          font-size: 14px;
          color: vars.$primary-color;
          margin-bottom: 0;
        }
      }
    }
  }

  .educator-appointment-wrapper {
    position: relative;
    width: 100%;
    background-color: black;
    border-radius: 20px;
    height: 100%;
    overflow-y: auto;
    max-width: 1700px;
    padding: 40px 20px;

    .appointment-container {
      border-radius: 20px;
      padding: 20px;

      .appointment-detail {
        line-height: 14px;
        color: vars.$white;
        font-size: 12px;
      }

      .a-calendar-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;

        .calendar-container {
          .calendar {
            .calendar-header-container {
              padding: 10px 13px;

              .calendar-header-text {
                font-family: vars.$gotham-bold;
                font-weight: bolder;
                margin-bottom: 10px;
              }

              .calendar-month-year-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 5px;
                margin-bottom: 10px;

                .month-year-text {
                  font-family: vars.$gotham-bold;
                  font-weight: bolder;
                  font-size: 16px;
                  margin-bottom: -15px;
                }

                .calendar-arrows-container {
                  display: flex;
                  align-items: center;
                  gap: 20px;
                }
              }
            }
          }
        }

        .appointment-time-container {
          max-width: 350px;
          width: 100%;
          background-color: vars.$white;
          border-radius: 20px;
          padding: 20px 20px;

          .at-month-day-text {
            font-family: vars.$gotham-bold;
            font-weight: bolder;
            font-size: 16px;
            color: vars.$black;
            padding: 0 20px;
          }

          .at-times-wrapper {
            max-height: 300px;
            padding: 0 20px;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            overflow-y: auto;
            cursor: pointer;

            .outlined-div {
              height: 40px;
              flex-shrink: 0;
              border-radius: 40px;
              border: 2px solid vars.$primary-color;
              background-color: transparent;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.3s all;

              &:is(&:hover, &.is-selected) {
                background-color: vars.$primary-color;
                color: white;
              }

              span {
                color: vars.$black;
                display: flex;

              }
            }
          }
        }
      }
    }

    .educator-appointment-container {
      border-radius: 20px;
      padding: 50px 20px;

      .eda-title {
        font-family: vars.$gotham-bold;
        font-weight: bolder;
        padding-left: 10px;
      }

      .edi-thumbnail-container {
        margin-top: 10%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 70%;
          height: 70%;
        }
      }
    }
  }
}

.calendar-container {
  .calendar {
    border-radius: 20px;
    width: 350px;
    padding: 15px 20px;
    background-color: vars.$white;

    div,
    p,
    span,
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: vars.$black;
    }

    th {
      font-family: vars.$gotham-bold;
      font-weight: bold;
      color: vars.$black;
    }

    .ant-picker-panel {
      border-radius: 20px;
      background-color: vars.$white;

      .ant-picker-body {
        .ant-picker-content {

          .ant-picker-cell-disabled {
            opacity: 0.5;
          }

          tbody {
            td.ant-picker-cell {
              div.ant-picker-cell-inner {
                margin-left: 7px;
                height: 30px;
                width: 30px;
                border-radius: 15px;
                background-color: vars.$primary-color-light;
                display: flex;
                align-items: center;
                justify-content: center;

                div.ant-picker-calendar-date-value {
                  margin-bottom: -8px;
                }
              }

              &:hover {
                div.ant-picker-cell-inner {
                  background-color: vars.$primary-color-dark !important;
                }
              }
            }

            td.ant-picker-cell-today {
              div.ant-picker-cell-inner {
                &::before {
                  border: 1px solid vars.$primary-color;
                  border-radius: 20px;
                  transform: scale(1.15);
                }
              }
            }

            td.ant-picker-cell-selected {
              div.ant-picker-cell-inner {
                div {
                  color: vars.$white;
                }

                background-color: vars.$primary-color-dark;

                &::before {
                  position: absolute;
                  left: 0;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  z-index: 1;
                  content: '';
                  border: 1.5px solid vars.$black;
                  border-radius: 20px;
                  transform: scale(1.25);
                }
              }
            }
          }
        }
      }
    }
  }


}