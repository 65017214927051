@use 'styles/scss/variables.scss' as vars;

.faq-page-container {
  padding: 40px 30px 10px 100px;
  .faq-title-wrapper {
    padding: 20px;
    .faq-title {
      font-weight: 900;
      color: vars.$primary-color;
    }
  }
  .faq-list-container {
    .ant-collapse {
      background-color: transparent;
      .ant-collapse-item {
        background-color: vars.$dark-grey;
        border-radius: 0 20px 20px 0;
        margin-bottom: 20px;
        .ant-collapse-header {
          height: 60px;
          display: flex;
          align-items: center;
          transition: 0.3s all;
          span.ant-collapse-header-text {
            font-size: 16px;
            font-weight: bolder;
            margin-bottom: -10px;
          }
          span.anticon {
            font-size: 16px;
            margin-top: -2px;
            color: vars.$primary-color;
            transition: 0.3s color;
          }
        }
        .ant-collapse-item {
          opacity: 0;
        }
      }
      .ant-collapse-item-active {
        background-color: vars.$grey;
        .ant-collapse-header {
          border-radius: 0 20px 0px 0;
          span.ant-collapse-header-text {
            font-size: 16px;
            font-weight: bolder;
            margin-bottom: -10px;
          }
          span.anticon {
            color: vars.$black;
          }
        }
        .ant-collapse-item {
          opacity: 0;
        }
        .ant-collapse-content {
          border-radius: 0 0 20px 0;
        }
      }
    }
  }
}
