/*
@media (max-width: $breakpoint-md) {
}

@media screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-xl) {
  
}

@media screen and (min-width: $breakpoint-xl) and (max-width: $breakpoint-xxl) {
  
}
@media (min-width: $breakpoint-xxl) {
}
*/

.dashboard-sidebar {
  @media (max-width: $breakpoint-xxl) {
    .go-live-wrapper {
      margin-top: 20px;
    }

    .d-sidebar-list-items {
      margin-top: 35px;
    }

    .d-sidebar-list-items {
      margin-top: 20px;
      margin-bottom: 0;

      .sli-container {
        height: 45px;

        .sli-img-container {
          width: 30px;
          height: 30px;
        }

        .sli-text-container {
          height: 35px;

          .sli-text {
            font-size: 12px;
          }
        }
      }
    }

    .ds-setting-container {
      padding: 10px 20px;

      .gc-primary-btn {
        height: 35px;
      }
    }
  }

  @media (max-width: $breakpoint-xl) {
    .go-live-wrapper {
      height: 35px;
      margin-top: 10px;

      .go-live-text {
        font-size: 11px;
      }

      .right>p {
        font-size: 11px !important;
      }
    }

    .d-sidebar-list-items {
      margin-top: 12px;
      margin-bottom: 0;

      .sli-container {
        height: 40px;

        .sli-img-container {
          width: 25px;
          height: 25px;
        }

        .sli-text-container {
          height: 35px;

          .sli-text {
            font-size: 11px;
            margin-bottom: -6px;
          }
        }
      }
    }
  }

  @media (max-width: $breakpoint-lg) {
    .go-live-wrapper {
      height: 35px;
      margin-top: 10px;

      .right>p {
        font-size: 11px !important;
      }
    }
  }
}

.dashboard-page-container {
  @media (max-width: $breakpoint-md) {
    .mcc-card-container {
      .card-container {
        height: 100px;
        margin-bottom: 10px;

        .card-wrapper {
          height: 100px;

          .card-text>.c-text {
            font-size: 14px;
          }
        }
      }
    }

    .content-layout {
      border-radius: 0;

      .content-wrapper {
        max-height: calc(100vh - 56px);
        height: 100%;
        border-radius: 8px 8px 0 0;
      }
    }
  }

  @media (max-width: $breakpoint-lg) {
    .map-detail-card-container {
      justify-content: flex-start;
    }
  }

  @media (max-width: $breakpoint-xxl) {
    .mcc-card-container {
      .card-container {
        height: 100px;
        margin-bottom: 10px;

        .card-wrapper {
          height: 100px;

          .card-text>.c-text {
            font-size: 16px;
          }
        }
      }
    }

    .content-layout {
      .content-wrapper {
        height: auto;
        padding-bottom: 20px;
      }
    }
  }

  @media (max-width: $breakpoint-xl) {
    .mcc-card-container {
      .card-container {
        height: 80px;
        margin-bottom: 10px;

        .card-wrapper {
          border-radius: 10px;
          height: 80px;

          .c-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

.faq-page-container {
  @media (max-width: $breakpoint-xl) {
    padding: 40px 30px;
  }
}

.affiliate-page-container {
  @media (max-width: $breakpoint-xxl) {
    padding: 0px 30px !important;

    .ags-start-container {
      .ags-second-container {
        margin-top: 0px !important;
      }
    }
  }

  @media (max-width: $breakpoint-xl) {
    .as-subscription-container {
      position: relative;
      // margin-top: 35px;
      width: 100% !important;
      padding-right: 0;
      right: 0;
      // transform:translateY(60px)
    }

    .adc-card-container {
      .card-container {
        height: 170px;
        width: calc(100% - 20px);
        margin-left: 10px;

        .number {
          font-size: 62px;
        }
      }

      .withdraw-card {
        .number {
          font-size: 42px;
        }
      }
    }

    .ag-goal-container {
      .info-container {

        .platinum,
        .ar-text {
          display: none;
          margin-bottom: 20px;
        }

        h3 {
          font-size: 16px;
        }
      }
    }

    .as-subscription-container {
      .top-title-container {
        height: 60px;
      }
    }

    .as-subscription-container {
      gap: 10px;

      .as-sub-card-container {
        height: 300px;

        .top-title-container {
          height: 60px;

          .title {
            font-size: 18px;
            margin-bottom: -4px;
          }
        }

        .bottom-desc-container {
          padding: 15px 5px;

          .highlighted {
            font-size: 18px;
          }
        }
      }
    }
  }

  @media (max-width: $breakpoint-lg) {
    padding: 30px 10px;

    .ah-header-container {
      .ah-avatar-text {
        .ah-avatar {
          height: 80px;
          width: 80px;
        }

        .ah-user-details {
          .ah-username {
            .username {
              font-size: 18px;
              max-width: 70%;
            }
          }
        }
      }

      button {
        // height: 40px;
        max-width: 120px;
      }
    }
  }

  @media (max-width: $breakpoint-sm) {
    .as-subscription-container {
      flex-direction: column;
      margin-top: 30px;
      padding-right: 40px;

      .as-sub-card-container {
        height: 260px;
        width: calc(100% - 40px);
      }
    }
  }
}

.affiliate-page-container {
  @media (max-width: $breakpoint-md) {
    padding: 30px 30px 0;

    .ags-second-container {
      padding-top: 40px;
    }

    .ags-start-container {
      padding-top: 40px;

      .ags-title {
        .title {
          font-size: 21px;
        }

        .paragraph {
          font-size: 11px;
        }

        button {
          height: 40px;
          width: 120px !important;
          border-radius: 10px;
        }
      }
    }

    .as-subscription-container {
      flex-direction: column;
      margin-top: 30px;
      justify-content: center;
      align-items: center;

      .as-sub-card-container {
        height: 250px;
        width: calc(100% - 50px);

        .top-title-container {
          height: 45px;

          div {
            font-size: 18px;
          }
        }

        .bottom-desc-container>h3 {
          // padding: 20px;
          font-size: 14px;
        }
      }
    }
  }

  @media screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-xl) {
    padding: 30px 30px 0;

    .ags-start-container {
      padding-bottom: 40px;

      .ags-title {
        .title {
          font-size: 24px;
        }

        .paragraph {
          font-size: 12px;
        }

        button {
          height: 40px;
          width: 110px !important;
          border-radius: 10px;

          div {
            font-size: 12px;
          }
        }
      }
    }

    .ags-second-container {
      padding-top: 40px;

      .ags-title {
        .title {
          font-size: 21px;
        }

        .paragraph {
          font-size: 11px;
        }
      }
    }

    .as-subscription-container {
      .as-sub-card-container {
        height: 250px;
        border-radius: 10px;

        .top-title-container {
          height: 50px;

          .title {
            font-size: 18px;
            margin-bottom: -12px;
          }
        }

        .bottom-desc-container {
          padding: 15px 10px;

          .highlighted {
            font-size: 12px;
          }
        }
      }
    }
  }

  @media screen and (min-width: $breakpoint-xl) and (max-width: $breakpoint-xxl) {
    padding: 20px 20px 0 !important;

    .ags-start-container {
      .ags-title {
        .title {
          font-size: 26px;
        }

        .paragraph {
          font-size: 14px;
        }

        button {
          height: 40px;
          width: 150px !important;
          border-radius: 10px;
        }
      }
    }

    .ags-second-container {
      .ags-title {
        .title {
          font-size: 21px;
        }

        .paragraph {
          font-size: 12px;
        }
      }
    }

    .as-subscription-container {
      position: relative;
      // margin-top: 35px;
      padding-right: 0;
      right: 0;
      // transform:translateY(60px)
    }

    .as-subscription-container {
      .top-title-container {
        height: 60px;
      }
    }

    .as-subscription-container {
      gap: 10px;

      .as-sub-card-container {
        height: 300px;

        .top-title-container {
          height: 60px;

          .title {
            font-size: 18px;
            margin-bottom: -12px;
          }
        }

        .bottom-desc-container {
          padding: 25px 15px;

          .highlighted {
            font-size: 18px;
          }
        }
      }
    }
  }

  @media (min-width: $breakpoint-xxl) {
    padding: 50px 100px 0;

    .ags-start-container {
      .ags-second-container {
        margin-top: 20px;
      }
    }
  }
}

.main-chat-container {
  @media (max-width: $breakpoint-md) {
    .item-time-text {
      font-size: 12px !important;
    }

    .chat-text {
      font-size: 12px !important;
    }

    .item-username {
      font-size: 12px !important;
    }
  }

  @media screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-xl) {
    .item-time-text {
      font-size: 11px !important;
    }

    .chat-text {
      font-size: 11px !important;
    }

    .item-username {
      font-size: 11px !important;
    }
  }
}

.course-details-page-container {
  @media (max-width: $breakpoint-md) {
    .course-title-btn-container {
      margin-top: 24px;
      gap: 15px;

      .course-title-wrapper {
        display: flex;
        flex-direction: column;

        .course-detail-title {
          font-size: 24px;
        }

        .desc-container {
          .desc-text {
            font-size: 16px;
          }

          span.by-text {
            font-size: 16px;
          }

          span.desc-icon {
            font-size: 15px;
          }
        }
      }
    }

    .course-information-container {
      margin-top: 20px;

      .cif-info-title {
        font-size: 14px;

        margin: 10px 0;
      }

      .cif-info-detail {
        font-size: 10px;
      }

      .lessons-list-container {
        height: auto;
        gap: 20px;

        .lesson-item {
          border-radius: 10px;
          padding: 20px 10px;

          .lesson-name-text {
            font-size: 12px;
            width: 70%;
          }

          .lesson-duration-text {
            font-size: 12px;
          }
        }
      }
    }
  }

  @media screen and (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) {
    .course-title-btn-container {
      margin-top: 24px;
      gap: 15px;

      .course-title-wrapper {
        display: flex;
        flex-direction: column;

        .course-detail-title {
          font-size: 24px;
        }

        .desc-container {
          .desc-text {
            font-size: 16px;
          }

          span.by-text {
            font-size: 16px;
          }

          span.desc-icon {
            font-size: 15px;
          }
        }
      }
    }

    .course-information-container {
      margin-top: 20px;

      .cif-info-title {
        font-size: 14px;

        margin: 10px 0;
      }

      .cif-info-detail {
        font-size: 10px;
      }

      .lessons-list-container {
        height: auto;
        gap: 20px;

        .lesson-item {
          border-radius: 10px;
          padding: 20px 10px;

          .lesson-name-text {
            font-size: 12px;
            width: 70%;
          }

          .lesson-duration-text {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.service-modal-container {
  @media (max-width: $breakpoint-md) {
    .ant-modal {
      max-width: 350px;
      max-width: 350px;
      min-width: 350px;
    }
  }
}

.mentorship-details-page-container {
  padding: 30px 30px 0 0;
  overflow-x: hidden;

  @media (max-width: $breakpoint-xs) {
    .mentorship-details-container {
      max-height: fit-content;
    }

    .mdp-image-container {
      justify-content: flex-start;
      margin-left: 15px;

      .mdp-image {
        max-height: 300px;
        max-width: 30 0px;
      }
    }

    .mdp-details {
      padding: 30px 0 0 15px;
    }

    .mdp-social-container {
      flex-wrap: wrap;

      .mdp-social-text {
        font-size: 11px;
      }

      svg {
        height: 14px;
        width: 14px;
      }
    }

    .mdp-post-list-container {
      max-height: fit-content;
      padding: 20px 0;

      .mdp-post-container {
        img {
          height: 240px;
        }
      }
    }

    .mdp-button-container {
      margin-top: 10px;
      gap: 10px;

      button {
        height: 38px;
        width: 100px;
        padding: 0;
        border-radius: 10px;

        div {
          font-size: 11px;
        }
      }
    }
  }

  @media screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-xl) {
    .mentorship-details-container {
      max-height: fit-content;
    }

    .mdp-image-container {
      justify-content: flex-start;
      margin-left: 15px;

      .mdp-image {
        max-height: 300px;
        max-width: 300px;
      }
    }

    .mdp-details {
      padding: 30px 0 0 15px;
    }

    .mdp-post-list-container {
      max-height: fit-content;

      .mdp-post-container {
        img {
          height: 220px;
        }
      }
    }

    .mdp-button-container {
      margin-top: 10px;
      gap: 10px;

      button {
        height: 38px;
        width: 150px !important;
        padding: 0;
        border-radius: 10px;

        div {
          font-size: 14px;
        }
      }
    }
  }

  @media screen and (min-width: $breakpoint-xl) and (max-width: $breakpoint-xxl) {
    padding: 20px 0 0 0;

    .mentorship-details-container {
      max-height: 400px;
    }

    // .mdp-image-container {
    //   .mdp-image {
    //     max-width: 300px;
    //   }
    // }

    .mdp-details {
      padding: 30px 0 0 20px;
      gap: 10px;

      .mdp-title-container {
        display: flex;
        align-items: center;
        gap: 10px;

        .mdp-title {
          font-size: 26px;
          margin-bottom: 5px;
        }

        .mdp-icon {
          font-size: 26px;
          margin-top: -15px;
        }
      }

      .mdp-about-title {
        font-size: 18px;
        margin-bottom: -4px;
      }

      .mdp-about-description {
        font-weight: lighter;
        font-size: 12px;
      }

      .mdp-button-container {
        margin-top: 10px;
        gap: 15px;

        button {
          height: 40px;
          width: 150px !important;
          border-radius: 12px;
        }
      }

      .mdp-social-container {
        .mdp-social-text {
          font-size: 14px;
        }

        svg {
          height: 18px;
          width: 18px;
        }
      }
    }

    .mdp-post-list-container {
      max-height: calc(100% - 470px);

      .mdp-post-container {
        img {
          height: 280px;
        }
      }
    }
  }

  @media (min-width: $breakpoint-xxl) {}
}

.educator-page-container {
  @media (max-width: $breakpoint-sm) {
    padding: 20px 10px 0;

    .educator-appointment-wrapper {
      padding: 10px;
    }

    .calendar-container,
    .appointment-time-container {
      transform: scale(0.8);
    }
  }

  @media (max-width: $breakpoint-xl) {
    flex-direction: column;
    padding: 30px 20px 0;
    gap: 10px;

    .educators-list-container {
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      min-height: 300px;
      max-height: 400px;
      overflow: auto;
      gap: 15px;
      padding-right: 10px;
      direction: rtl;

      .educator-list-item {
        width: calc(100% - 20px);
        height: 130px;
        min-height: 130px;
        border-radius: 15px;
        gap: 15px;
        padding: 10px;

        .edl-image {
          width: 20%;
          border-radius: 20px;
        }

        .educator-details {
          padding: 5px 0 10px 0;

          .educator-detail-info-container {
            .educator-title {
              gap: 15px;

              .educator-title-text {
                font-size: 21px;
                margin-bottom: -16px;
              }
            }

            .educator-desc-text {
              font-size: 16px;
            }
          }

          .educator-call-text {
            font-size: 16px;
          }
        }
      }
    }

    .educator-appointment-wrapper {
      margin-top: 20px;
      overflow: visible;
      height: fit-content;

      .appointment-container {
        padding: 10px;

        .appointment-detail {
          font-size: 11px;
        }

        .a-calendar-wrapper {
          .calendar-container {
            .calendar {
              .calendar-header-container {
                padding: 5px 8px;

                .calendar-header-text {
                  font-family: $gotham-bold;
                  font-weight: bolder;
                  margin-bottom: 10px;
                }

                .calendar-month-year-container {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 0 2px;
                  margin-bottom: 10px;

                  .month-year-text {
                    font-family: $gotham-bold;
                    font-weight: bolder;
                    font-size: 14px;
                    margin-bottom: -15px;
                  }

                  .calendar-arrows-container {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                  }
                }
              }
            }
          }


        }
      }
    }
  }

  @media screen and (min-width: $breakpoint-xl) and (max-width: $breakpoint-xxl) {
    padding: 30px 10px 0;
    gap: 10px;

    .educators-list-container {
      width: 240px;
      min-width: 240px;
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding-right: 10px;
      direction: rtl;

      .educator-list-item {
        width: calc(100% - 10px);
        height: 90px;
        min-height: 90px;
        border-radius: 12px;
        gap: 8px;
        padding: 5px;

        &:hover {
          transform: scale(1.01);
        }

        &:active {
          transform: scale(0.99);
        }

        .edl-image {
          width: 30%;
          border-radius: 10px;
        }

        .educator-details {
          padding: 0 0 8px 0;

          .educator-detail-info-container {
            .educator-title {
              gap: 5px;

              .educator-title-text {
                overflow: hidden;
                max-width: 80%;
                font-size: 14px;
              }
            }

            .educator-desc-text {
              font-size: 12px;
              max-width: 120px;
            }
          }

          .educator-call-text {
            font-size: 12px;
            color: $primary-color;
            margin-top: 5px;
            margin-bottom: 0;
          }
        }
      }
    }

    .educator-appointment-wrapper {

      .appointment-container {
        padding: 10px;

        .appointment-detail {
          font-size: 11px;
        }

        .a-calendar-wrapper {

          // display: flex;
          // align-items: center;
          // justify-content: center;
          .calendar-container {
            .calendar {
              .calendar-header-container {
                padding: 5px 8px;

                .calendar-header-text {
                  font-family: $gotham-bold;
                  font-weight: bolder;
                  margin-bottom: 10px;
                }

                .calendar-month-year-container {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 0 2px;
                  margin-bottom: 10px;

                  .month-year-text {
                    font-family: $gotham-bold;
                    font-weight: bolder;
                    font-size: 14px;
                    margin-bottom: -15px;
                  }

                  .calendar-arrows-container {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                  }
                }
              }
            }
          }


        }
      }
    }

    .appointment-time-container,
    .calendar {
      transform: scale(0.8);
    }
  }

  @media (min-width: $breakpoint-xxl) {}
}


.admin-page-container {
  padding: 0 30px;

  .admin-page-content {
    margin-top: 30px;
  }

}

.account-page-wrapper {
  padding: 0 30px;

  .account-page-content {
    margin-top: 30px;
  }
}