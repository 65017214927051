// colors
$primary-color: #57C1EB;
$primary-color-light: #FFF1CB;
$primary-color-dark: #57C1EB;
$default-color: #939393;
$grey: #727272;
$dark-grey: #292929;
$black: #000000;
$white: #ffffff;
$light-grey: #eaeaea;
$red: #ff0000;
$font-color: #fff;
$font-invert-color: #000;
$platinum-color: #9d9d9d;

// status colors
$success-color: #00b300;
$warning-color: #ffb300;
$error-color: #ff0000;

// backgrounds
$header-bg: #f4f4f4;
$sidebar-bg: #D8D8D8;

// font sizes
$font-size-xsmall: 10px;
$font-size-small: 12px;
$font-size-base: 14px;
$font-size-medium: 18px;
$font-size-large: 20px;
$font-size-xlarge: 24px;
$font-size-xxlarge: 30px;
$font-size-huge: 37px;

// font weights for normal , semibold , and bold
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-xbold: 800;

// header height
$page-navbar-height: 80px;

//shadow
$card_box_shadow_color: #9d9d9d29;
$custom-modal-bg-light: #fbfbfc;
$custom-modal-bg-dark: #1e1e1e;

// font weights

$gotham-light: 'Gotham Light';
$gotham-normal: 'Gotham';
$gotham-medium: 'Gotham Book';
$gotham-bold: 'Gotham Ultra';

// transparent colors

$transparent: #00000000;
$black-20: rgba(0, 0, 0, 0.2);
$light-grey-5: #53535320;

// gradients
$grey-to-dark: linear-gradient(90deg, #262626, transparent);
$grey-to-transparent-vertical: linear-gradient(180deg, $light-grey, transparent);
$tb-gradient: linear-gradient(180deg, #EBEBEB, transparent);
$primary-gradient: linear-gradient(90deg, #d4a945, #cdb26f);

// breakpoints
$breakpoint-xs: 576px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1600px;

$box-shadow: 1px 5px 20px -10px rgba(0, 0, 0, 0.5);
$box-shadow-invert: 1px 20px 35px -20px rgba(255, 255, 255, 0.5);
