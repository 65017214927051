body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  font-family: $gotham-normal;

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $black;
    border: 1.5px solid $grey;
    border-radius: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $black;
  }
}

mark {
  background-color: transparent;
}


.ant-form-item-explain-error {
  font-size: 12px;
  margin-top: 5px;
}

.ant-upload-list-item-name {
  white-space: normal !important;
}

.ant-upload-list-item {
  height: auto !important;
  align-items: flex-start !important;
}

.ant-btn-primary {
  &:disabled {
    background-color: lighten($color: $primary-color, $amount: 10);
    color: white;
    border: 0;

  }
}