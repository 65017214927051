@use 'styles/scss/variables.scss' as vars;

.course-modal {

    .upload-placeholder {
        padding: 10px;
        min-height: 74px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 20px;
        border: 3px solid #707070;
        justify-content: center;
        align-items: center;
        width: 130px;
        cursor: pointer;

        img {
            width: 34px;
            height: 34px;
        }
    }

    .add-another-wrapper {
        display: inline-flex;
        gap: 10px;
        align-items: center;
        cursor: pointer;

        button {
            padding: 10px 15px;
            border-radius: 20px;
            background-color: vars.$white;
            outline: none;
            border: 0;
            cursor: pointer;

            span {
                font-size: 20px;
                color: vars.$primary-color;

            }
        }

    }

    .course-modal-footer {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 10px;

    }

    .course-detail-grid {
        display: flex;
        flex-direction: column;

    }
}