@use 'styles/scss/variables.scss' as vars;

.account-tab {
    display: flex;
    gap: 10px;

    .at-title {
        transition: 0.3 all;
        cursor: pointer;
    }

    &.active {
        .at-title {
            color: vars.$primary-color;
        }
    }


}

.my-info-wrapper {
    .ant-form-item-label {
        padding: 0;

        label {
            color: #FFEAD8 !important;
            font-family: vars.$gotham-bold !important;
        }
    }

    input {
        padding-left: 0;
    }

    .edit-icon {
        color: vars.$primary-color;
        text-decoration: underline;
        text-decoration-style: dotted;
        cursor: pointer;
    }
}

.projects-wrapper,
.subscription-wrapper {

    .project-card,
    .subscription-card {
        border-radius: 20px;
        max-height: 120px;
        height: 100%;
        padding: 14px 10px;
        width: 100%;
        background-color: #222222;
        display: flex;
        gap: 10px;
        align-items: center;

        img {
            height: 100%;
            max-width: 100px;
            width: 100%;
        }

        .right {
            display: flex;
            flex-direction: column;
        }

    }
}