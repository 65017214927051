a,h1,h2,h3,h4,h5,h6,p,span,strong,div{
    font-family: "Gotham Serif", sans-serif;
}
.login-area{
    .left-content{
        background: #EBEBEB;
        height: 100vh;
        .top-content{
            h1{
                font-size: 39px;
                font-weight: 700;
                line-height: 59px;
                letter-spacing: -0.02em;
                text-align: left;
    
            }
            p{
                padding: 12px 0;
                font-size: 20px;
                font-weight: 400;
                line-height: 29px;
                letter-spacing: 0em;
                text-align: left;
            }
            
        }
        .ion{
            top: 3px;
            img{
                width: 24px;
                height: 24px;
            }
        }
        input{
            padding-left: 45px;
            height: 60px;
            border-radius: 9px;
        }
        .btn{
            font-size: 24px;
        }
        .forget-password{
            padding-top: 10px;
            a{
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: right;
                color: rgba(0, 0, 0, 1);
            }
        }
        .auth-btn{
            display: block;
            width: 100%;
            background-color: rgba(8, 7, 21, 1);
            height: 60px;
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            color: rgba(255, 255, 255, 1) !important;
            margin:  20px 0;
        }
        .gc-primary-btn .gc-primary-btn-text{
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            color: rgba(255, 255, 255, 1) !important;
        }
        .divider-list{

            .divider{
                width: 100%;
                height: 1px ;
                background-color: rgba(0, 0, 0, 0.5);
            }
            p{
            font-size: 16px;
            font-style: italic;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: center;
            margin: 0 3px;
            color: rgba(0, 0, 0, 0.5);
            }
            
        }
        .signup-text{
           a{
            text-align: center;
            display: block;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: center;
            text-decoration: underline;
            color: rgba(0, 0, 0, 0.5);;
            strong{
                font-size: 16px;
                font-weight: 500;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: center;
            }
           }
    
        }
    }
    .left-content-2{
        width: 80%; /* Set the width of the container */
        margin: 0 auto; /* Center the container horizontally */
        height: auto; /* Set the height of the container for demonstration */
        display: flex; /* Use flexbox for layout */
        justify-content: center; /* Center the content horizontally */
        align-items: center; /* Center the content vertically */
        overflow: auto;
        .top-content{
            margin-top: 110px;
            margin-bottom: 60px;
            h1{
                font-size: 39px;
                font-weight: 700;
                line-height: 59px;
                letter-spacing: -0.02em;
                text-align: left;

            }
            p{
                padding: 12px 0;
                font-size: 20px;
                font-weight: 400;
                line-height: 29px;
                letter-spacing: 0em;
                text-align: left;
            }

        }
        .ion{
            top: 3px;
            img{
                width: 24px;
                height: 24px;
            }
        }
        input{
            padding-left: 45px;
            height: 60px;
            border-radius: 9px;
            background-color: #fff;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(0, 0, 0, 1);
            &::placeholder{
                font-size: 20px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: rgba(0, 0, 0, 1);
            }
        }
        .btn{
            font-size: 24px;
        }
        .forget-password{
            padding-top: 10px;
            a{
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: right;
                color: rgba(0, 0, 0, 1);
            }
        }
        .auth-btn{
            display: block;
            width: 100%;
            background-color: rgba(8, 7, 21, 1);
            height: 60px;
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            color: rgba(255, 255, 255, 1) !important;
            margin:  20px 0;
        }
        .gc-primary-btn .gc-primary-btn-text{
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            color: rgba(255, 255, 255, 1) !important;
        }
        .divider-list{
            .divider{
                width: 50%;
                height: 1px ;
                background-color: rgba(255, 255, 255, 1);
            }
            p{
                font-size: 25px;
                font-style: italic;
                font-weight: 500;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: center;
                margin: 0 3px;
                color: rgba(255, 255, 255, 1);
            }

        }
        .signup-text{
            a{
                text-align: center;
                display: block;
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: center;
                text-decoration: underline;
                color: rgba(255, 255, 255, 1);
                strong{
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 30px;
                    letter-spacing: 0em;
                    text-align: center;
                }
            }

        }
    }
    .right-content{
        height: 100vh;
        background-color: #000;
        .effect{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        .overly-box{
            h2{
                font-size: 49px;
                font-weight: 500;
                line-height: 54px;
                letter-spacing: 0em;
                text-align: center;
                color: rgba(255, 255, 255, 1);
            }
        }
    }
}

