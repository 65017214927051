@use 'styles/scss/variables.scss' as vars;

.welcome-page-wrapper {
    height: 100vh;

    .wp-top {
        height: 60%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px 0;
    }
    .wp-bottom {
        height: 40%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 50px 0;
    }

    .wp-top {
        background-color: #F4F4F4;

        .welcome-text {
            color: vars.$black;
            text-align: center;
            margin-bottom: -10px;
        }

        .welcome-logo {
          height: 50%;
            object-fit: contain;
        }
    }

    .wp-bottom {
        background-size: cover;
        background-repeat: no-repeat;
        background: linear-gradient(108.33deg, #57C1EB 21.98%, #57C1EB 82.4%);
       position: relative;
       &::before{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/img/welcomeBg.png');
        top: 0;
        left: 0;
        z-index: 0;
        background-repeat: no-repeat;
        background-size: cover;
        right: 0;
        bottom: 0;
        display: block;
       }
       .wp-content{
        z-index: 111;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content:space-between;
        align-items: flex-start;

        
       }
       .bottom-bar{
        width: 100%;
        .top-divider{
            padding-top: 15px;
            display: flex;
            align-items: center;
            .divider{
                width: 50%;
                display: block;
                height: 1px;
                background-color: rgba(254, 254, 254, 1);
            }
            p{
                font-family: 'Gotham Serif', sans-serif;
                font-size: 25px;
                font-style: italic;
                font-weight: 500;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: center;
                color: rgba(255, 235, 235, 1);
                margin: 0 5px;
            }
        }
       
    }
    .copy-right{
        padding-top: 15px;
        mark{
            color: rgba(255, 255, 255, 1);
            font-family: 'Gotham Serif', sans-serif;
            font-size: 25px;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: left;
        }

    }
       .title{
        font-family: 'Gotham Serif', sans-serif;
        font-size: 30px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(0, 0, 0, 1);
       }
       .details{
        font-family: 'Gotham Serif', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(35, 28, 13, 1);
        width: 70%;
        margin: auto;
        word-break: break-all;
       }
       .auth-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 20px;

       }
       .login-btn{
        width: 235px;
        height: 60px;
        border-radius: 7px;
        border: none;
        background-color: rgba(255, 255, 255, 1);
        font-family: 'Gotham Serif', sans-serif;
        font-size: 23px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        margin-right: 10px;
       }
       .sign-up-btn{
        margin-left: 10px;
        width: 235px;
        height: 60px;
        border-radius: 7px;
        border: none;
        background-color: rgba(255, 255, 255, 1);
        font-family: 'Gotham Serif', sans-serif;
        font-size: 23px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
       }
        .welcome-button {
            display: inline-flex;
            gap: 10px;
            min-height: 45px;
            align-items: center;
            min-width: 140px;
            border-radius: 18px;
            padding-left: 8px;
            padding-right: 8px;


            &.login {
                background-color: vars.$primary-color;
            }

            &.signup {
                background-color: transparent;
            }

            img {
                width: 30px;
                height: 30px;
            }

        }

        .trailer-mark {
            cursor: pointer;
            user-select: none;
            text-decoration: underline;
        }


    }
}