@use 'styles/scss/variables.scss' as vars;

.course-details-page-container {
  padding: 2% 5% 2% 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  .course-detail-container {
    width: 100%;
    //max-width: 1280px;

    .course-video-wrapper {
      display: flex;
      align-items: center;
      position: relative;
      padding-top: 44.51%;
      background-color: black;
      border-radius: 30px;
      width: 100%;

      .video-box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 20px;
        overflow: hidden;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .course-title-btn-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 38px;
    gap: 20px;
    width: 100%;

    .course-title-wrapper {
      display: flex;
      flex-direction: column;

      .course-detail-title {
        font-family: vars.$gotham-bold;
        color: vars.$primary-color;
        margin-bottom: 10px;
        font-size: 39px;
      }

      .desc-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        gap: 10px;

        .desc-text {
          font-size: 21px;
          font-weight: bolder;
          margin: 0;
          overflow: hidden;
          color: black;
          text-overflow: ellipsis;
        }

        span.by-text {
          font-size: 21px;
          font-family: 'Gotham Light';
          font-weight: 100;
          margin-top: -10px;
        }

        span.desc-icon {
          font-size: 20px;
          margin-top: -6px;

          &.gold {
            color: #FFD700;

          }

          &.green {
            color: vars.$success-color ;
          }

          &.platinium {

            color: #E5E4E2;

          }
        }
      }
    }
  }

  .course-information-container {
    margin-top: 30px;
    width: 100%;

    .cif-info-title {
      font-size: 16px;
      color: vars.$primary-color;
      font-family: vars.$gotham-bold;
      font-weight: bolder;
    }

    .cif-info-detail {
      font-size: 16px;
      color: black;
      font-weight: lighter;
    }

    .lessons-list-wrapper {
      position: relative;

      .lessons-upper-overlay {
        position: absolute;
        top: 0;
        width: 100%;
        height: 60px;
        background: vars.$tb-gradient;
        z-index: 10;
        pointer-events: none;
      }

      .lessons-lower-overlay {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 60px;
        background: vars.$tb-gradient;
        z-index: 10;
        transform: rotate(180deg);
        pointer-events: none;
      }
    }

    .lessons-list-container {
      width: 100%;
      height: 190px;
      overflow-y: auto;
      padding-right: 5px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: relative;
      padding: 20px 0;

      .lesson-item {
        width: 100%;
        // min-height: 50px;
        padding: 20px 18px;
        background-color: vars.$white;
        border-radius: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 8px;

        .lesson-name-text {
          font-size: 14px;
          font-weight: bolder;
          font-family: vars.$gotham-bold;
          color: vars.$font-invert-color;
          width: 70%;
          word-break: break-all;
        }

        .lesson-duration-text {
          font-size: 14px;
          color: vars.$font-invert-color;
          padding-left: 15px;
        }
      }
    }
  }

}


.lesson-file-content {

  .lesson-file-divider {
    margin-top: 4px;
    margin-bottom: 8px;
  }

  .lesson-file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #424242;
    gap: 20px;
    padding: 5px 10px;
    border-radius: 4px;

    h5 {
      margin: 0;
      font-size: 0.8rem;
    }

  }
}