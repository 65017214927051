@use 'styles/scss/variables.scss' as vars;

.custom-modal {

    backdrop-filter: blur(6px);

    .ant-modal {
        .ant-modal-content {
            background-color: vars.$black;
            border-radius: 20px;
            padding: 20px;
        }
    }
}