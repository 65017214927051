@use 'styles/scss/variables.scss' as vars;

.service-modal {
  .ant-modal {
    // min-width: 760px;
    .ant-modal-content {
      background-color: vars.$black;
      border-radius: 20px;
      padding: 20px;
      .service-image {
        width: 350px;
        height: 360px;
        object-fit: cover;
        border-radius: 16px;
        @media (max-width: vars.$breakpoint-sm) {
          width: 250px;
          height: 180px;
        }

        @media screen and (min-width:vars.$breakpoint-sm) and (max-width: vars.$breakpoint-md) {
          width: 350px;
          height: 220px;
        }
      }
      .service-title {
        font-family: vars.$gotham-bold;
        margin-bottom: 5px;
      }
      .service-text {
        color: vars.$primary-color;
      }
      .service-input {
        padding:10px 20px 10px 20px;
        width: 80%;
        color:vars.$black;
        border-radius: 30px;
        background-color: vars.$white;
        caret-color: vars.$primary-color;
      }
      .service-radio {
        .ant-radio-wrapper > span.ant-radio {
          margin-top: -3px;
        }
      }
      .service-slider-container {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-top: -10px;
        button {
          height: 40px;
          border-radius: 14px;
        }
        .slider-wrapper {
          width: 40%;
          .ant-slider {
            .ant-slider-rail {
              height: 6px;
              background-color: vars.$dark-grey;
            }
            .ant-slider-track {
              margin-top: -1px;
              height: 8px;
              background-color: vars.$primary-color;
            }
            .ant-slider-handle {
              height: 13px;
              width: 13px;
              &::after {
                height: 16px;
                width: 16px;
                margin-top: -2px;
              }
              &::before {
                height: 13px;
                width: 13px;
              }
            }
          }
        }
      }
      .service-submit-btn {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 25px;
        border-radius: 20px;
        margin-top: 30px;
        .service-submit-text {
          font-size: 16px;
          margin-bottom: -10px;
          font-weight: vars.$gotham-bold;
          font-weight: bolder;
        }
      }
    }
  }
}
