@use 'styles/scss/variables' as vars;

.loader-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    gap: 35px;

    &.overlay {
        height: 100vh;
        position: fixed;
        inset: 0;
    }

    .loader {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: vars.$primary-color;
        position: relative;
    }

    .loader:before,
    .loader:after {
        content: "";
        position: absolute;
        border-radius: 50%;
        inset: 0;
        background: black;
        transform: rotate(0deg) translate(30px);
        animation: rotate 1s ease infinite;
    }

    .loader:after {
        animation-delay: 0.5s;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg) translate(30px);
        }
    }
}