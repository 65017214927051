// Spacing

.mt-1 {
    margin-top: 0.25rem;
}

.mt-2 {
    margin-top: 0.5rem;
}

.mt-3 {
    margin-top: 1rem;
}

.mt-4 {
    margin-top: 1.5rem;
}

.mt-5 {
    margin-top: 3rem;
}

.mb-1 {
    margin-bottom: 0.25rem;
}

.mb-2 {
    margin-bottom: 0.5rem;
}

.mb-3 {
    margin-bottom: 1rem;
}

.mb-4 {
    margin-bottom: 1.5rem;
}

.mb-5 {
    margin-bottom: 3rem;
}

.ml-1 {
    margin-left: 0.25rem;
}

.ml-2 {
    margin-left: 0.5rem;
}

.ml-3 {
    margin-left: 1rem;
}

.ml-4 {
    margin-left: 1.5rem;
}

.ml-5 {
    margin-left: 3rem;
}

.mr-1 {
    margin-right: 0.25rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.mr-3 {
    margin-right: 1rem;
}

.mr-4 {
    margin-right: 1.5rem;
}

.mr-5 {
    margin-right: 3rem;
}

.mx-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
}

.mx-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}

.mx-3 {
    margin-right: 1rem;
    margin-left: 1rem;
}

.mx-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
}

.mx-5 {
    margin-right: 3rem;
    margin-left: 3rem;
}

.my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.my-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.my-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.my-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

// Display

.d-none {
    display: none;
}

.d-inline {
    display: inline;
}

.d-inline-block {
    display: inline-block;
}

.d-block {
    display: block;
}

.d-flex {
    display: flex;
}

.d-grid {
    display: grid;
}

.d-table {
    display: table;
}

.d-table-row {
    display: table-row;
}

.d-table-cell {
    display: table-cell;
}

// Text

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-wrap {
    white-space: normal;
}

.text-nowrap {
    white-space: nowrap;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
}

// Colors

.text-primary {
    color: $primary-color;
}

.bg-primary {
    background-color: $primary-color;
}



.text-success {
    color: $success-color;
}

.bg-success {
    background-color: $success-color;
}



.text-warning {
    color: $warning-color;
}

.bg-warning {
    background-color: $warning-color;
}