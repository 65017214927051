@use 'styles/scss/variables.scss' as vars;

// chat styling
.main-chat-container {
  width: 100%;
  height: calc(100vh - 95px);
  padding: 0 10px;
  .chat-header-container {
    // padding-top: 20px;
    height: 62px;
    display: flex;
    align-items: center;
    gap: 15px;
    .ch-outlined {
      height: 30px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: 1px solid vars.$black;
      img {
        height: 16px;
        object-fit: contain;
        margin-right: 6px;
      }
      .ch-btn-text {
        color: vars.$font-invert-color;
        font-weight: bold;
      }
    }
    .ch-title-text {
      color: vars.$font-invert-color;
    }
  }
  .c-chat-container {
    height: calc(100% - 70px);
    width: 100%;
    .clc-container {
      position: relative;
      width: 100%;
      height: calc(100% - 65px);
      border-radius: 20px;
      overflow: hidden;

      .clc-gradient-overlay {
        position: absolute;
        top: 0;
        width: 100%;
        height: 90px;
        background: vars.$grey-to-transparent-vertical;
        z-index: 10;
      }
      .clc-list-container {
        background-color: vars.$light-grey;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        overflow-y: auto;
        direction: to bottom;
        scroll-behavior: smooth;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0 8px 0;
        // justify-content: flex-end;
        &::-webkit-scrollbar {
          display: none;
        }
        scrollbar-width: 0;
        .clc-lock-overlay {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 12%;
          }
        }
        .chat-item-container {
          padding: 6px 8px;
          width: 100%;
          .chat-item-right-container {
            display: flex;
            justify-content: flex-end;
            .item-right {
              border-radius: 20px 20px 0 20px;
              background-color: vars.$primary-color;
              overflow: hidden;
              padding: 10px 14px 10px 14px;
            }
          }
          .chat-item-left-container {
            display: flex;
            gap: 7px;
            flex-direction: column;
            justify-content: flex-end;
            .item-left {
              border-radius: 0px 20px 20px 20px;
              background-color: vars.$white;
              overflow: hidden;
              padding: 10px 14px 10px 14px;
            }
            .item-left-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .item-avatar {
                height: 29px;
                width: 29px;
                border-radius: 15px;
                flex-shrink: 0;
                border: 1px solid vars.$black;
                background-color: vars.$white;
                margin-right: 7px;
              }
              .item-avatar-text {
                display: flex;
                align-items: center;
              }
              .item-username {
                margin-bottom: -10px;
                font-size: 14px;
                color: vars.$font-invert-color;
              }
              .item-checked {
                margin-left: 7px;
                font-size: 14px;
                margin-top: -2px;
                color: vars.$primary-color;
              }
              .item-time-text {
                margin-bottom: -10px;
                font-size: 12px;
                color: vars.$font-invert-color;
              }
            }
          }

          .premium-chat {
            .item-left-header {
              .item-avatar {
                border: 1px solid vars.$primary-color;
              }
            }
            .item-left {
              background-color: vars.$black;
              span.chat-text {
                color: vars.$white;
                font-size: 14px;
                line-height: 16px;
              }
            }
          }
          span.chat-text {
            color: vars.$black;
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }
    .ci-container {
      margin-top: 10px;
      min-height: 55px;
      width: 100%;
      background-color: red;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 20px;
      background-color: vars.$light-grey;
      .ci-input-wrapper {
        width: calc(100% - 60px);
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding: 5px 0;
        textarea.ci-input {
          padding: 5px 10px;
          caret-color: vars.$primary-color !important;
          border: none;
          background-color: transparent;
          color: vars.$black;
          // margin-top: 5px;
          width: calc(100% - 20px);
          margin-left: 10px;
          // background-color: red;
          &:focus {
            // border: none !important;
            box-shadow: none;
          }
        }
      }
      .ci-send-button {
        border: none;
        height: 55px;
        width: 55px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: vars.$primary-color;
      }
    }
  }
}
