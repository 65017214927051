.nfp-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 320px;
  .nfp-title {
    margin-top: -8vh;
    font-weight: bold;
    color: black;
    font-size: 3.375rem;
    span {
      font-size: 3.125rem;
    }
  }
}
