@use 'styles/scss/variables.scss' as vars;

.admin-listing-card {
    padding: 0 28px;
    border-radius: 20px;
    background-color: #272727;
    height: 240px;


    .admin-listing-card-header {
        height: 50px;
        padding-top: 20px;
    }

    .admin-listing-cards-wrapper {
        display: flex;
        flex-direction: column;
        height: calc(100% - 105px);
        overflow-y: scroll;
        gap: 5px;
        margin-bottom: 15px;
        padding-right: 10px;

        .alc-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        /* Define the scrollbar styles */
        &::-webkit-scrollbar {
            width: 7px;
            height: 7px;
            background-color: transparent;
        }

        /* Define the track styles */
        &::-webkit-scrollbar-track {
            background-color: transparent;
            border: 1px solid #000000;
        }

        /* Define the thumb styles */
        &::-webkit-scrollbar-thumb {
            background-color: #D2B164;
            border-radius: 7px;
            border: 0;
        }

    }

    .admin-listing-card-footer {
        height: 55px;
        border-top: 2px solid vars.$primary-color;
        padding-top: 10px;
        margin-right: 5px;
    }
}