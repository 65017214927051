@font-face {
  font-family: $gotham-light;
  src: local("Gotham-Light"),
    url("../../assets/fonts/GothamPro-Light/GothamPro-Light.ttf") format("truetype");
}

@font-face {
  font-family: $gotham-normal;
  src: local("Gotham-Normal"),
    url("../../assets/fonts/GothamPro/GothamPro.ttf") format("truetype");
}

@font-face {
  font-family: $gotham-medium;
  src: local("Gotham-Medium"),
    url("../../assets/fonts/GothamPro-Medium/GothamPro-Medium.ttf") format("truetype");
}

@font-face {
  font-family: $gotham-bold;
  src: local("Gotham-Bold"),
    url("../../assets/fonts/GothamPro-Bold/GothamPro-Bold.ttf") format("truetype");
}

h1,
h2,
h3,
h4,
h5 {
  color: $black;
  line-height: 5.5;
  margin: 0;
}

h1 {
  font-size: $font-size-xxlarge;
  font-family: $gotham-bold;
}

h2 {
  font-size: $font-size-xlarge;
  font-family: $gotham-bold;
}

h3 {
  font-size: $font-size-large;
  font-family: $gotham-bold;
}

h4 {
  font-size: $font-size-medium;
  font-family: $gotham-medium;
}
h5 {
  font-size: $font-size-base;
  font-family: $gotham-medium;
}

p {
  font-size: $font-size-base;
  margin: 0;
}
