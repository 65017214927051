// utils

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.d-none {
  display: none;
}

.uppercase {
  text-transform: uppercase;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.gap-10 {
  gap: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-underline {
  text-decoration: underline;
}


// weights

.font-semibold {
  font-family: $gotham-medium !important;
}

.font-bold {
  font-family: $gotham-bold !important;
}

// text sizes

.text-small {
  font-size: $font-size-small;
}

.text-huge {
  font-size: $font-size-huge;
}

// text colors

.primary-text-color {
  color: $primary-color !important;
}

.text-white {
  color: $white !important;
}

.text-black {
  color: $black !important;
}

.w-100-imp {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

.blurred-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  backdrop-filter: blur(6px);
}

.df-row {
  display: flex;
  align-items: center;
}

.form-item-direction-row {
  .ant-row {
    flex-direction: row !important;

    label {
      margin-top: 10px;
    }

  }
}

.flex-direction-row {
  flex-direction: row !important;
}

// font position adjustment
.-mb-3-imp {
  margin-bottom: -3px !important;
}

.flex-1 {
  flex: 1;
}

.lh-0 {
  line-height: 0;
}

.min-width-0 {
  min-width: 0 !important;
}

//colors


.gold {
  color: #FFD700;

}

.green {

  color: $success-color ;
}

.platinium {

  color: #E5E4E2;

}