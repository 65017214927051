@use 'styles/scss/variables.scss' as vars;

.dashboard-page-container {
  padding: 0 20px;

  .dp-title-container {
    padding-top: 54px;
    margin-left: 0px;
    font-weight: bold;
    margin-bottom: 50px;
    color: black;
  }

  // Map Container component styles
  .map-detail-card-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: calc(100vh - 250px);
  }

  .mc-map-details-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .mc-map-container {
      img {
        width: 100%;
        object-fit: contain;
      }
    }

    .mc-details-container {
      display: flex;
      gap: 16px;
      flex-direction: column;
      align-items: center;

      .detail-item-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 66px;
        width: 160px;
        border-radius: 20px;
        gap: 14px;
        background: vars.$grey-to-dark;

        .mdi-primary-text,
        .mdi-secondary-text {
          margin-bottom: 0px;
        }

        .mdi-secondary-text {
          font-weight: bold;
          margin-bottom: -2px;
        }

        @media (max-width: vars.$breakpoint-xl) {
          width: 100%;
          height: 55px;

          .mdi-primary-text,
          .mdi-secondary-text {
            font-size: 11px;
          }
        }
      }
    }
  }

  .mcc-card-container {
    width: 100%;
    margin-top: 30px;
    justify-content: space-evenly;

    .card-container {
      height: 176px;
      width: 100%;
      // width: 100%;
      justify-content: start;

      .card-wrapper {
        position: relative;
        height: 176px;
        width: 100%;
        border-radius: 20px;
        background-color: vars.$grey;
        overflow: hidden;

        .card-image {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .card-text {
          position: absolute;
          bottom: 12px;
          right: 20px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;

          .c-text {
            font-weight: bold;
            color: vars.$font-invert-color;
          }

          .card-underline {
            height: 8px;
            width: 110px;
            background-color: vars.$primary-color;
          }

          @media (max-width: vars.$breakpoint-md) {

            // max-width: 100%;
            .c-text {
              font-size: 21px;
            }
          }
        }
      }
    }
  }
}