.account-page-wrapper {
    .account-page-content {
        .ac-top {
            display: flex;
            flex-direction: row-reverse;

            .logout-btn {
                color: #D19E2A;
                text-decoration: underline;
                text-decoration-style: dotted;
            }
        }
    }
}