@use 'styles/scss/variables.scss' as vars;


.image-picker-upload-form-item {
    margin-bottom: 0;
    height: 100%;

    .ant-row {
        height: 100%;

        .ant-col {
            height: 100%;

            .ant-form-item-control-input {
                height: 100%;
            }
        }
    }


    .ant-form-item-control-input~div {
        margin-top: -30px;
        z-index: 9;
        text-align: center;
        position: relative;
        max-width: 308px;

        @media (max-width: vars.$breakpoint-md) {
            max-width: 100%;
        }
    }
}

.image-picker-upload {
    .ant-upload-list {
        display: none;
    }
}

.image-picker {
    width: 100%;
    height: 100%;
    min-height: 335px;


    img {
        position: absolute;
        width: 100%;
        height: 100%;
        max-width: 308px;
        inset: 0;
        object-fit: cover;
        border-radius: 20px;

        &.with-shadow {
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
        }


    }

    .ip-text-wrapper {
        position: absolute;
        z-index: 2;
        bottom: 40px;
        text-align: center;
        width: 100%;
        max-width: 308px;

    }

    @media (max-width: vars.$breakpoint-md) {

        img,
        .ip-text-wrapper {
            max-width: 100%;
        }



    }

}